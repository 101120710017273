import React from "react";

import Layout from "../components/Layout";

export default function Impressum() {
  return (
    <Layout pageTitle={`Impressum`}>
      <div className="impressum site-content_inner">
        <div>
          <h2>Impressum</h2>

          <div className="impressum-box">
            <p>
              <span>
                Gesellschaft der Freunde und Förderer des Städtischen
                Luisen-Gymnasiums zu Düsseldorf e.V.
              </span>
              <span>
                Eingetragen in das Vereinsregister beim Amtsgericht Düsseldorf
                VR 4109
              </span>
            </p>
            <p>
              <span>
                Im Sekretariat des Städtischen Luisen-Gymnasiums zu Düsseldorf
              </span>
              <span>Bastionstr. 24</span>
              <span>40213 Düsseldorf</span>
            </p>
            <p>
              <span>Martin Heil (1. Vorsitzender)</span>
              <span>foerderverein@luisen-gymnasium.de</span>
            </p>
          </div>

          <h3>Haftungsausschluss</h3>

          <h4>1. Inhalt des Onlineangebotes</h4>
          <p>
            Der Autor über&shy;nimmt keiner&shy;lei Gewähr für die
            Aktuali&shy;tät, Korrekt&shy;heit, Voll&shy;ständig&shy;keit oder
            Qualität der bereit&shy;gestel&shy;lten Infor&shy;mationen.
            Haftungs&shy;an&shy;sprüche gegen den Autor, welche sich auf Schäden
            materiel&shy;ler oder ideeller Art be&shy;ziehen, die durch die
            Nutzung oder Nicht&shy;nutzung der dar&shy;gebotenen
            Infor&shy;mationen bzw. durch die Nutzung fehler&shy;hafter und
            un&shy;voll&shy;ständiger Infor&shy;mationen ver&shy;ur&shy;sacht
            wurden, sind grund&shy;sätzlich aus&shy;ge&shy;schlossen, sofern
            seitens des Autors kein nach&shy;weis&shy;lich
            vor&shy;sätz&shy;liches oder grob fahr&shy;lässiges Ver&shy;schulden
            vor&shy;liegt.
          </p>
          <p>
            Alle Angebote sind frei&shy;bleibend und
            un&shy;ver&shy;bindl&shy;ich. Der Autor behält es sich
            aus&shy;drück&shy;lich vor, Teile der Seiten oder das gesamte
            Angebot ohne ge&shy;sonderte An&shy;kündi&shy;gung zu
            ver&shy;ändern, zu er&shy;gänzen, zu löschen oder die
            Ver&shy;öffent&shy;lichung zeit&shy;weise oder end&shy;gültig
            ein&shy;zu&shy;stellen.
          </p>

          <h4>2. Verweise und Links</h4>
          <p>
            Bei direkten oder in&shy;direkten Ver&shy;weisen auf fremde
            Web&shy;seiten ("Hyperlinks"), die außer&shy;halb des
            Ver&shy;antwortungs&shy;bereiches des Autors liegen, würde eine
            Haftungs&shy;ver&shy;pflichtung aus&shy;schließlich in dem Fall in
            Kraft treten, in dem der Autor von den Inhalten Kennt&shy;nis hat
            und es ihm tech&shy;nisch möglich und zumut&shy;bar wäre, die
            Nutzung im Falle rechts&shy;widriger Inhalte zu ver&shy;hindern.
          </p>
          <p>
            Der Autor erklärt hiermit aus&shy;drücklich, dass zum Zeit&shy;punkt
            der Link&shy;setzung keine illegalen Inhalte auf den zu
            ver&shy;linkenden Seiten er&shy;kenn&shy;bar waren. Auf die aktuelle
            und zu&shy;künftige Ge&shy;staltung, die Inhalte oder die
            Urheber&shy;schaft der ver&shy;linkten/ver&shy;knüpften Seiten hat
            der Autor keiner&shy;lei Einfluss. Deshalb distanziert er sich
            hiermit aus&shy;drücklich von allen Inhalten aller ver&shy;linkten
            ver&shy;knüpften Seiten, die nach der Link&shy;setzung
            ver&shy;ändert wurden. Diese Fest&shy;stellung gilt für alle
            inner&shy;halb des eigenen Internet&shy;angebotes ge&shy;setzten
            Links und Verweise sowie für Fremd&shy;ein&shy;träge in vom Autor
            ein&shy;ge&shy;richteten Gästeb&shy;üchern, Diskussions&shy;foren,
            Link&shy;verzeich&shy;nissen, Mailing&shy;listen und in allen
            anderen Formen von Daten&shy;banken, auf deren Inhalt externe
            Schreib&shy;zu&shy;griffe möglich sind. Für illegale,
            fehler&shy;hafte oder un&shy;voll&shy;ständige Inhalte und
            ins&shy;besondere für Schäden, die aus der Nutzung oder
            Nicht&shy;nutzung solcher&shy;art dar&shy;ge&shy;botener
            Infor&shy;mationen ent&shy;stehen, haftet allein der An&shy;bieter
            der Seite, auf welche ver&shy;wiesen wurde, nicht der&shy;jenige,
            der über Links auf die je&shy;weilige Ver&shy;öffent&shy;lichung
            lediglich ver&shy;weist.
          </p>

          <h4>3. Urheber- und Kennzeichenrecht</h4>
          <p>
            Der Autor ist bestrebt, in allen Publikationen die
            Urheber&shy;rechte der ver&shy;wendeten Bilder, Grafiken,
            Ton&shy;dokumente, Video&shy;sequenzen und Texte zu beachten, von
            ihm selbst er&shy;stellte Bilder, Grafiken, Ton&shy;dokumente,
            Video&shy;sequenzen und Texte zu nutzen oder auf lizenz&shy;freie
            Grafiken, Ton&shy;dokumente, Video&shy;sequenzen und Texte
            zurück&shy;zu&shy;greifen.
          </p>
          <p>
            Alle inner&shy;halb des Internet&shy;an&shy;ge&shy;botes
            ge&shy;nannten und ggf. durch Dritte ge&shy;schützten Marken- und
            Waren&shy;zeichen unter&shy;liegen un&shy;ein&shy;geschränkt den
            Be&shy;stim&shy;mungen des jeweils gültigen
            Kenn&shy;zeichen&shy;rechts und den Besitz&shy;rechten der
            je&shy;weiligen ein&shy;ge&shy;tragenen Eigen&shy;tümer. Allein
            auf&shy;grund der bloßen Nennung ist nicht der Schluss zu ziehen,
            dass Marken&shy;zeichen nicht durch Rechte Dritter ge&shy;schützt
            sind.
          </p>
          <p>
            Das Copyright für ver&shy;öffent&shy;lichte, vom Autor selbst
            er&shy;stel&shy;lte Objekte bleibt allein beim Autor der Seiten.
            Eine Ver&shy;viel&shy;fältigung oder Ver&shy;wendung solcher
            Grafiken, Ton&shy;dokumente, Video&shy;sequenzen und Texte in
            anderen elek&shy;tronischen oder ge&shy;druck&shy;ten
            Publi&shy;kationen ist ohne aus&shy;drückliche Zu&shy;stimmung des
            Autors nicht ge&shy;stattet.
          </p>

          <h4>4. Rechtswirksamkeit dieses Haftungsausschlusses</h4>
          <p>
            Dieser Haftungs&shy;ausschluss ist als Teil des
            Internet&shy;angebotes des Förder&shy;vereins zu betrachten. Sofern
            Teile oder einzelne Formu&shy;lier&shy;ungen dieses Textes der
            gelten&shy;den Rechts&shy;lage nicht, nicht mehr oder nicht
            voll&shy;ständig ent&shy;sprechen sollten,bleiben die übrigen Teile
            des Dokumentes in ihrem Inhalt und ihrer Gültig&shy;keit davon
            un&shy;berührt.
          </p>
        </div>
      </div>
    </Layout>
  );
}
